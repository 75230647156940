import React, { Fragment } from "react";
import styles from "./block.module.scss";
import Author from "../../../resources/images/author/front.png";
import Wife from "../../../resources/images/author/carolyn.jpg";
import Happy from "../../../resources/images/author/happy.jpg";

const BlockLanding = () => {
  return (
    <Fragment>
      <div className="row mb-10 md-down_mx-9">
        <div className="small-12 large-2 mb-9">
          <img src={Author} alt="author" className={styles.bioPicFam} />
        </div>
        <div className="small-12 large-9 large-offset-1">
          <h4 className={styles.bioFamHeader}>R.D. Sutherland</h4>
          <p className="pb-6">
            R.D. Sutherland M.D. practiced thoracic and cardiovascular surgery
            for thirty years. Now lives in the Dallas metroplex, having fun with
            children and grandchildren and spending quality time with his wife
            Carolyn and dog Happy.
          </p>
          <p className="pb-6">
            Retired in 2010 from his occupation as a
            Cardiovascular Surgeon he still finds time to educate through his
            writing in ways that will leave you feeling as if you are in the OR
            alongside him.
          </p>
          <p className="pb-6">
            What inspires a surgeon to take his skills as a successful
            cardiovascular surgeon and interweave this knowledge through the
            creation and writing of intense, passionate mystery books.
          </p>
          <p className="pb-6">
            There’s no doubt that both his decades of work in the surgery field,
            as well as, his hobby as a professional air race pilot can require
            many solitary hours that allow a creative mind to create. For Dr.
            Sutherland, his stories have materialized in those moments of quiet
            thought before using his laser focus on surgery or air race flying.
          </p>
          <p className="pb-6">
            Most of his career, was as a thoracic and cardiovascular surgeon for
            26 years in Amarillo, Texas where he performed the first heart
            operation for High Plains Baptist Hospital in July 1976. During his
            years in private practice, he published 21 professional papers. He
            retired in 1998, and moved to Alto, New Mexico where he spent years
            honing his skills in air racing, during which he was awarded the 2008
            National Champion, Gold, Sport Air Racing League and also raced four
            years in the National Air Races in Reno, Nevada.
          </p>
          <p className="pb-6">
            Yet the call to care for others was still strong and in 2010,
            Sutherland returned to cardiovascular surgery. The next several years
            were spent teaching and tending to patients. Retirement came for real
            in 2018 as his writing became the next big passion to fulfill and
            conquer.
          </p>
        </div>
      </div>
      <div className="row my-10 md-down_mx-9">
        <div className="small-12 large-2 mb-9">
          <img src={Wife} alt="wife" className={styles.bioPicFam} />
        </div>
        <div className="small-12 large-9 large-offset-1">
          <h4 className={styles.bioFamHeader}>Carolyn</h4>
          <p className="pb-6">
            Carolyn is a wonderful wife, taking care of me and our dog Happy.
            She attended Texas Tech University and worked in the food brokerage
            business. She loves sports and follows all the Texas college and
            professional teams. Between us with five children, twelve
            grandchildren and six great grandchildren she is very busy. I taught
            her to fly, so she bought her own airplane, a Piper Archer. She
            would fly around West Texas and New Mexico.
          </p>
        </div>
      </div>
      <div className="row my-10 md-down_mx-9">
        <div className="small-12 large-2 mb-9">
          <img src={Happy} alt="happy" className={styles.bioPicFam} />
        </div>
        <div className="small-12 large-9 large-offset-1">
          <h4 className={styles.bioFamHeader}>Happy</h4>
          <p className="pb-6">
            Happy is our dog, a Chavachon, a mix between a Cavalier King Spaniel
            and a Bichon. Happy is very smart, doesn't shed, is loving and a
            constant companion to us. He takes me on a two mile walk twice a
            day.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default BlockLanding;

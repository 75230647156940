import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./app/components/main";
import Books from "./app/components/books";
import Contact from "./app/components/contact";
import Publications from "./app/components/publications";
import Memberships from "./app/components/memberships";
import Photos from "./app/components/photos";
import NotFound from "./app/components/404";
import Exception from "./app/components/500";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/books" component={Books} />
        <Route path="/publications" component={Publications} />
        <Route path="/memberships" component={Memberships} />
        <Route path="/photos" component={Photos} />
        <Route path="/contact" component={Contact} />
        <Route path="/exception" component={Exception} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<Routes />, document.getElementById("devp-app"));

import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import GalleryBooks from "./gallery-books";
import SectionRow from "../layouts/section";
import SectionTwoLeft from "../layouts/section-2col-left";
import SectionTwoRight from "../layouts/section-2col-right";
import { BlockBio, BlockLanding } from "./blocks";
import styles from "./main.module.scss";

class Main extends Component {
  render() {
    return (
      <main className={styles.wrapper}>
        <Header />
        <SectionRow>
          <GalleryBooks />
          <SectionTwoLeft>
            <BlockBio />
          </SectionTwoLeft>
          <SectionTwoRight leader="About The Author">
            <BlockLanding />
          </SectionTwoRight>
        </SectionRow>
        <Footer />
      </main>
    );
  }
}

export default Main;

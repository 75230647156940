import React, { Component } from "react";
import PropTypes from "prop-types";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import SectionRow from "../layouts/section";
import SectionTwoLeft from "../layouts/section-2col-left";
import SectionTwoRight from "../layouts/section-2col-right";
import { BlockAuthor } from "./blocks";
import Book from "../../resources/images/book.jpg";
import Book2 from "../../resources/images/book2.jpg";
import styles from "./main.module.scss";

const BookImage = props => {
  return (
    <div className="small-12 large-4 mb-6 p-6">
      <img
        {...props}
        alt={
          "book-image" +
          Math.random()
            .toString(36)
            .slice(2)
        }
      />
    </div>
  );
};

const BookBlock = props => {
  return <div className="small-12 large-8 mb-6 p-6">{props.children}</div>;
};

class Books extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <Header active="books" />
        <SectionRow>
          <SectionTwoLeft>
            <BlockAuthor />
          </SectionTwoLeft>
          <SectionTwoRight leader="Books">
            <section className="row expanded xl_my-9 xl_pb-8">
              <BookImage src={Book} className={styles.book} />
              <BookBlock>
                <div className="mb-10 md-down_mx-9">
                  <h4 className={styles.sectionHeaderRed}>
                    The Other Side of the Knife
                  </h4>
                  <p className="py-6">
                    Dan Parker is a cardiovascular surgeon who has taken on an
                    international chase for the holy grail of heart surgery, a
                    self-contained implantable artificial heart.
                  </p>
                  <p className="py-6">
                    Dan&apos;s Life is in jeopardy when he chases a corrupt
                    Robert Krum from Austin, to the Mescalero Apache
                    reservation, to an overpopulated city in India where illegal
                    human heart transplants are being done, and then back to New
                    Mexico.
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.amazon.com/Other-Side-Knife-R-D-Sutherland-ebook/dp/B07FN7Z8YC/"
                    className={styles.btnRed}
                  >
                    Buy Online
                  </a>
                </div>
              </BookBlock>
            </section>
            <section className="row expanded xl_my-9 xl_pb-8">
              <BookImage src={Book2} className={styles.book} />
              <BookBlock>
                <div className="mb-10 md-down_mx-9">
                  <h4 className={styles.sectionHeaderBlue}>
                    The Surgeon and the Arrow
                  </h4>
                  <p className="py-6">
                    Dr. Jamie Swift is a pediatric heart surgeon and a champion
                    archer. She performs a perfect operation on an infant and he
                    dies in the recovery room. The psychotic angry father, Jack
                    Hunter, plans to murder Jamie. Her car explodes with a bomb.
                    She tries to get away and goes to Sedona. There she is
                    kidnapped and dropped in the wilderness. Two weeks later,
                    minutes from death she is rescued. Later, she sees the man
                    that kidnapped her and shoots him with an arrow. After
                    returning to San Antonio, she adopts a Mexican orphan with a
                    congenital heart problem. She later confronts Jack Hunter.
                    Jack is killed with an arrow. The ending is surprising and
                    exciting.
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.amazon.com/Surgeon-Arrow-Medical-Thriller-Book-ebook/dp/B0859SSYSY/"
                    className={styles.btnBlueLight}
                  >
                    Buy Online
                  </a>
                </div>
              </BookBlock>
            </section>
          </SectionTwoRight>
        </SectionRow>
        <Footer />
      </div>
    );
  }
}

BookBlock.propTypes = {
  children: PropTypes.node
};

export default Books;

import React from "react";
import PropTypes from "prop-types";
import styles from "./section-2col-left.module.scss";

const SectionTwoLeft = props => {
  return <div className={styles.row}>{props.children}</div>;
};

SectionTwoLeft.propTypes = {
  children: PropTypes.node
};

export default SectionTwoLeft;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { ChevronDown, ChevronUp } from "react-feather";
import cx from "classnames";
import styles from "./nav.module.scss";

class NavMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayNav: false
    };

    // this.showNav = this.showNav.bind(this);
    // this.hideNav = this.hideNav.bind(this);
    this.handleNav = this.handleNav.bind(this);
  }

  handleNav = e => {
    e.preventDefault();
    this.setState(state => ({
      displayNav: !state.displayNav
    }));
  };

  render() {
    const { displayNav } = this.state;
    const { active } = this.props;
    return (
      <div className={styles.navMobile}>
        <nav>
          <button className="hover_opacity-75" onClick={this.handleNav}>
            Menu
            {displayNav ? (
              <ChevronUp size={18} className="pl-2 inline-block" />
            ) : (
              <ChevronDown size={18} className="pl-2 inline-block" />
            )}
          </button>
          {displayNav && (
            <ul className="pt-4 bg-gray-dark">
              <li>
                <a
                  className={cx(
                    styles.navlink,
                    active === "books" && styles.active
                  )}
                  href="/books"
                >
                  Books
                </a>              </li>
              <li>
                <a
                  className={cx(
                    styles.navlink,
                    active === "publications" && styles.active
                  )}
                  href="/publications"
                >
                  Publications
                </a>
              </li>
              <li>
                <a
                  className={cx(
                    styles.navlink,
                    active === "memberships" && styles.active
                  )}
                  href="/memberships"
                >
                  Memberships
                </a>
              </li>
              <li>
                <a
                  className={cx(
                    styles.navlink,
                    active === "photos" && styles.active
                  )}
                  href="/photos"
                >
                  Photos
                </a>
              </li>
              <li>
                <a
                  className={cx(
                    styles.navlink,
                    active === "contact" && styles.active
                  )}
                  href="/contact"
                >
                  Contact
                </a>
              </li>
            </ul>
          )}
        </nav>
      </div>
    );
  }
}

NavMobile.propTypes = {
  active: PropTypes.string
};

export default NavMobile;

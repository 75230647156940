import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import SectionRow from "../layouts/section";
import SectionTwoLeft from "../layouts/section-2col-left";
import SectionTwoRight from "../layouts/section-2col-right";
import { BlockAuthor } from "./blocks";
import styles from "./main.module.scss";

const memberships = [
  { name: "American College of Surgeons", link: "https://www.facs.org/" },
  { name: "Society of Thoracic Surgeons", link: "https://www.sts.org/" },
  { name: "Southern Thoracic Surgical Association", link: "https://stsa.org/" },
  { name: "American College of Cardiology", link: "https://www.acc.org/" },
  {
    name: "American College of Chest Physicians",
    link: "https://www.chestnet.org/"
  },
  {
    name: "Denton Cooley Cardiovascular Surgical Society",
    link:
      "https://www.bcm.edu/departments/surgery/about-us/legacy-leadership/denton-cooley"
  },
  {
    name: "American Association for Thoracic Surgery",
    link: "https://www.aats.org/aatsimis/AATSWeb"
  }
];

class Memberships extends Component {
  render() {
    return (
      <main className={styles.wrapper}>
        <Header active="memberships" />
        <SectionRow>
          <SectionTwoLeft>
            <BlockAuthor />
          </SectionTwoLeft>
          <SectionTwoRight leader="Society Memberships">
            {memberships &&
              memberships.map(v => {
                return (
                  <div className="py-8">
                    <a
                      href={v.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover_text-red-dark"
                    >
                      {v.name}
                    </a>
                  </div>
                );
              })}
          </SectionTwoRight>
        </SectionRow>
        <Footer />
      </main>
    );
  }
}

export default Memberships;

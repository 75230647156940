import React from "react";
import PropTypes from "prop-types";
import styles from "./section.module.scss";

const SectionRow = props => {
  return <section className={styles.section}>{props.children}</section>;
};

SectionRow.propTypes = {
  children: PropTypes.node
};

export default SectionRow;

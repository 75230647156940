import React, { Component } from "react";
import { ReactComponent as Panda } from "../../resources/images/panda-logo.svg";
import styles from "./footer.module.scss";

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className={styles.wrapper}>
        <Panda fill="#f5f5f5" width={32} height={32} />
        <div className={styles.copyright}>
          Copyright &copy; {year + " RD Sutherland M.D."}
        </div>
      </footer>
    );
  }
}

export default Footer;

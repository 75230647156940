import React from "react";
import PropTypes from "prop-types";
import styles from "./section-2col-right.module.scss";

const SectionTwoRight = props => {
  return (
    <div className={styles.row}>
      <div className={styles.leader}>
        <h1>{props.leader}</h1>
      </div>
      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  );
};

SectionTwoRight.propTypes = {
  leader: PropTypes.string,
  children: PropTypes.node
};

export default SectionTwoRight;

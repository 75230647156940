import React from "react";
import styles from "./block.module.scss";
import Bio from "../../../resources/images/author/bio.jpg";

const BlockBio = () => {
  return (
    <div className={styles.row}>
      <img src={Bio} alt="author" className={styles.image} />
      <div className={styles.bulletContainer}>
        <div className={styles.bulletHeader}>Military Status</div>
        <ul className={styles.bulletList}>
          <li className={styles.bulletItem}>
            Chief, Department of Surgery, 862 Medical Group, SAC
          </li>
          <li className={styles.bulletItem}>
            United States Air Force, April 1966 - 1968, Honorable Discharge
          </li>
        </ul>
      </div>
      <div className={styles.bulletContainer}>
        <div className={styles.bulletHeader}>Education</div>
        <ul className={styles.bulletList}>
          <li className={styles.bulletItem}>
            B.A., University of Colorado, Boulder, CO 1954 - 1957
          </li>
          <li className={styles.bulletItem}>
            M.D., University of Colorado Medical School, Denver, CO 1958 - 1961
          </li>
        </ul>
      </div>
      <div className={styles.bulletContainer}>
        <div className={styles.bulletHeader}>Residency</div>
        <ul className={styles.bulletList}>
          <li className={styles.bulletItem}>
            Hennepin County General Hospital, Minneapolis, MN
            <br />
            General Surgery: 1962 - 1966 &amp; 1968 - 1969
          </li>
          <li className={styles.bulletItem}>
            UT Southwestern Medical School and Affiliates, Dallas, TX
            <br />
            Thoracic and Cardiovascular Surgery: 1969 - 1971
          </li>
        </ul>
      </div>
      <div className={styles.bulletContainer}>
        <div className={styles.bulletHeader}>Board Certifications</div>
        <ul className={styles.bulletList}>
          <li className={styles.bulletItem}>
            American Board of Surgery: 1970 (#16914)
          </li>
          <li className={styles.bulletItem}>
            American Board of Thoracic Surgery: 1972 (#2455)
          </li>
          <li className={styles.bulletItem}>
            American Board of Thoracic Surgery: 1984 (Lifetime)
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlockBio;

const gallery = [
  {
    id: 1,
    src: "./assets/gallery/RDS-01.jpg",
    title: "gallery-01",
    caption: "R.D. Sutherland M.D.",
  },
  {
    id: 2,
    src: "./assets/gallery/RDS-02.jpg",
    title: "gallery-02",
    caption: "Carolyn and Happy",
  },
  {
    id: 3,
    src: "./assets/gallery/RDS-03.jpg",
    title: "gallery-03",
    caption: "Happy in his happy spot",
  },
  {
    id: 4,
    src: "./assets/gallery/RDS-04.jpg",
    title: "gallery-04",
    caption: "Tiger Cat",
  },
  {
    id: 5,
    src: "./assets/gallery/RDS-05.jpg",
    title: "gallery-05",
    caption: "Credit to Mark Johnston"
  },
  {
    id: 6,
    src: "./assets/gallery/RDS-06.jpg",
    title: "gallery-06",
    caption: "Carolyn and I",
  },
  {
    id: 7,
    src: "./assets/gallery/RDS-07.jpg",
    caption: "At the races",
  },
  {
    id: 8,
    src: "./assets/gallery/RDS-08.jpg",
    title: "gallery-08",
    caption: "Credit to Mark Johnston"
  },
  {
    id: 9,
    src: "./assets/gallery/RDS-09.jpg",
    title: "gallery-09",
    caption: "Open Air Racing League National Champion 2008",
  },
  {
    id: 10,
    src: "./assets/gallery/RDS-10.jpg",
    title: "gallery-10",
    caption: "Credit to Jon Peters"
  },
  {
    id: 11,
    src: "./assets/gallery/RDS-11.jpg",
    title: "gallery-11",
    caption: "Sierra Blanca",
  },
  {
    id: 12,
    src: "./assets/gallery/RDS-12.jpg",
    title: "gallery-12",
    caption: "My plane",
  },
  {
    id: 13,
    src: "./assets/gallery/RDS-13.jpg",
    title: "gallery-13",
    caption: "Credit to Mark Johnston"
  },
  {
    id: 14,
    src: "./assets/gallery/RDS-14.jpg",
    title: "gallery-14",
    caption: "My plane again",
  },
  {
    id: 15,
    src: "./assets/gallery/RDS-15.jpg",
    title: "gallery-15",
    caption: "Christmas time",
  },
  {
    id: 16,
    src: "./assets/gallery/RDS-16.jpg",
    title: "gallery-16",
    caption: "My uniform",
  },
  {
    id: 17,
    src: "./assets/gallery/RDS-17.jpg",
    title: "gallery-17",
    caption: "Target practice",
  },
  {
    id: 18,
    src: "./assets/gallery/RDS-18.jpg",
    title: "gallery-18",
    caption: "Happy is dressed up",
  },
  {
    id: 19,
    src: "./assets/gallery/RDS-19.jpg",
    title: "gallery-19",
    caption: "Cover of Book 1",
  },
  {
    id: 20,
    src: "./assets/gallery/RDS-20.jpg",
    title: "gallery-20",
    caption: "My father and I",
  }
];

export default gallery;

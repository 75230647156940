import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import SectionRow from "../layouts/section";
import SectionTwoLeft from "../layouts/section-2col-left";
import SectionTwoRight from "../layouts/section-2col-right";
import { BlockAuthor } from "./blocks";
import Gallery from "./gallery";
import styles from "./main.module.scss";

class Photos extends Component {
  render() {
    return (
      <main className={styles.wrapper}>
        <Header active="photos" />
        <SectionRow>
          <SectionTwoLeft>
            <BlockAuthor />
          </SectionTwoLeft>
          <SectionTwoRight leader="Photo Gallery">
            <Gallery />
          </SectionTwoRight>
        </SectionRow>
        <Footer />
      </main>
    );
  }
}

export default Photos;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
// import Modal from "react-modal";
import gallery from "./gallery-assets";
// import CloseButton from "../../../resources/icons/x.svg";
import PreviousButton from "../../../resources/icons/chevrons-left.svg";
import NextButton from "../../../resources/icons/chevrons-right.svg";
import styles from "./gallery.module.scss";

// Modal.setAppElement("#devp-app");

// const galleryStyles = {
//   overlay: {
//     backgroundColor: "rgba(0, 0, 0, 0.75)"
//   },
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     padding: "0",
//     marginRight: "-50%",
//     overflow: "none",
//     backgroundColor: "#a9a9a9",
//     transform: "translate(-50%, -50%)"
//   }
// };

const Slide = props => {
  const { total, assets } = props;
  return (
    <Fragment>
      <div className={styles.fadeIn}>
        <div className={styles.number}>
          {assets.id} / {total}
        </div>
        <img alt={assets.title} src={assets.src} className={styles.slide} />
      </div>
      <div className={styles.caption}>{assets.caption}</div>
    </Fragment>
  );
};

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      slide: 0,
      slides: gallery
    };

    this.handleGalleryState = this.handleGalleryState.bind(this);
    this.forceGalleryClose = this.forceGalleryClose.bind(this);
    this.forceGalleryOpen = this.forceGalleryOpen.bind(this);
  }

  handlePreviousSlide = () => {
    const { slide, slides } = this.state;
    const minusOne = slide - 1;
    const previous = minusOne === -1 ? slides.length - 1 : minusOne;
    this.setState({ slide: previous });
  };

  handleNextSlide = () => {
    const { slide, slides } = this.state;
    const plusOne = slide + 1;
    const next = plusOne === slides.length ? 0 : plusOne;
    this.setState({ slide: next });
  };

  handleGalleryState = e => {
    const { id } = e.target;
    const substr =
      id.length === 4 ? id.substr(id.length - 1) : id.substr(id.length - 2);

    this.setState(state => ({
      show: !state.show,
      slide: Number(substr)
    }));
  };

  handleKeyDown = e => {
    e.keyCode === 37 && this.handlePreviousSlide();
    e.keyCode === 39 && this.handleNextSlide();
  };

  forceGalleryClose() {
    this.setState({ show: false });
  }

  forceGalleryOpen() {
    this.setState({ show: true });
  }

  render() {
    const { slide, slides } = this.state;
    const numSlides = slides.length;

    return (
      <Fragment>
        <div className={styles.carousel}>
          <Slide total={numSlides} assets={slides[slide]} />
          <img
            alt="PreviousButton"
            src={PreviousButton}
            className={styles.prev}
            onClick={this.handlePreviousSlide}
          />
          <img
            alt="NextButton"
            src={NextButton}
            className={styles.next}
            onClick={this.handleNextSlide}
          />
        </div>
        <div className="row py-8">
          {gallery.map((v, i) => {
            return (
              <div
                key={v.id}
                className="columns large-2 medium-2 small-3"
                onClick={this.handleGalleryState}
              >
                <img
                  id={"rc-" + i}
                  src={v.src}
                  alt={v.title}
                  className="my-4 border-2 border-red-dark"
                />
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}

Slide.propTypes = {
  total: PropTypes.number,
  assets: PropTypes.object
};

export default Gallery;

/**
 * @summary RD Sutherland React Application.
 * @module 500 Internal Error
 * @description Landing page for Status Code 500
 */
import React from "react";
import Panda from "../../resources/images/panda-logo.png";
import styles from "./error.module.scss";

function Exception() {
  return (
    <div className={styles.container}>
      <img
        className={styles.logo}
        alt="Panda Logo"
        src={Panda}
        width="90"
        height="90"
      />
      <h1 className={styles.header}>500</h1>
      <p className={styles.content}>An Exception has occurred.</p>
    </div>
  );
}

export default Exception;

import React from "react";
import styles from "./block.module.scss";
import Author from "../../../resources/images/author/front.png";

const BlockAuthor = () => {
  return (
    <div className={styles.row}>
      <img src={Author} alt="author" className={styles.image} />
      <p className={styles.content}>
        <span className="text-red-dark">RD Sutherland M.D.</span> practiced
        thoracic and cardiovascular surgery for thirty years. Now lives in the
        Dallas metroplex, having fun with children and grandchildren and
        spending quality time with his wife Carolyn and dog Happy. Retired in
        2010 from his occupation as a Cardiovascular Surgeon he still finds time
        to educate through his writing in ways that will leave you feeling as if
        you are in the OR alongside him.
      </p>
    </div>
  );
};

export default BlockAuthor;

import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import SectionRow from "../layouts/section";
import SectionTwoLeft from "../layouts/section-2col-left";
import SectionTwoRight from "../layouts/section-2col-right";
import { BlockAuthor } from "./blocks";
import styles from "./main.module.scss";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
  };

  render() {
    return (
      <main className={styles.wrapper}>
        <Header active="contact" />
        <SectionRow>
          <SectionTwoLeft>
            <BlockAuthor />
          </SectionTwoLeft>
          <SectionTwoRight leader="Contact">
            <form onSubmit={this.handleSubmit}>
              <div className="m-4">
                <input
                  className="p-4 w-7/12"
                  type="text"
                  name="name"
                  value=""
                  placeholder="Your Name"
                />
              </div>
              <div className="m-4">
                <input
                  className="p-4 w-7/12"
                  type="text"
                  name="email"
                  value=""
                  placeholder="Email Address"
                />
              </div>
              <div className="m-4">
                <textarea
                  className="p-4"
                  rows="5"
                  cols="52"
                  name="comments"
                  value=""
                  placeholder="Enter your comments here.."
                />
              </div>
              <div className="m-4">
                <input
                  className="p-4 w-7/12 bg-red hover_bg-red-dark"
                  type="submit"
                  name="submit"
                  value="Send Message"
                />
              </div>
            </form>
          </SectionTwoRight>
        </SectionRow>
        <Footer />
      </main>
    );
  }
}

export default Contact;

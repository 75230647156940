import React from "react";
import styles from "./block.module.scss";

const BlockPublications = () => {
  return (
    <ol className={styles.listContainer}>
      <li className={styles.listItem}>
        Hitchcock, C.R., Bitter, J.E., Sutherland, R.D.:{" "}
        <span className="italic">
          Clinical Evaluation of Gastric Freezing for Duodenal Ulcer.
        </span>{" "}
        JAMA 188:409-411, May, 1964.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Bitter, J.D., Hitchcock, C.R.:{" "}
        <span className="italic">
          Rebound Hyperacidity After Gastric Freezing.
        </span>{" "}
        Arch. Surgery 89:208-214, July, 1964.
      </li>
      <li className={styles.listItem}>
        Hitchcock, C.R., Ruiz, E., Sutherland, R.D., Bitter, J.E.:{" "}
        <span className="italic">
          Eighteen Month Follow up Gastric Freezing in 173 patients with
          Duodenal Ulcer.
        </span>{" "}
        JAMA 195:7175, January, 1966.
      </li>
      <li className={styles.listItem}>
        Hoerr, G.O., Sutherland, R.D., Mishkin, M.E.:{" "}
        <span className="italic">
          Ligation of Inferior Vena Cava in Early Pregnancy.
        </span>{" "}
        JAMA 205:177, September, 1968.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Heglin, J.J: <span className="italic">Tetanus.</span>{" "}
        Minnesota Medical, P. 87, January, 1986.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Stanger, p., Climie, A.R.W., Quinn, M.H.F., Edwards,
        J.:{" "}
        <span className="italic">
          Large Anomalous Fibrous Sac in Right Side of Heart.
        </span>{" "}
        Circ. 39:837-840, June, 1969.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Karns, M., Edwards, J:{" "}
        <span className="italic">
          Intrapulmonary Vein Contributing a Segment of Venous Supply of
          Contralateral Lung.
        </span>{" "}
        CHEST 57:182-184, February, 1970.
      </li>
      <li className={styles.listItem}>
        Franco-Vasquez, S., Sutherland, R.D., Fowler, M., Edwards, J.:{" "}
        <span className="italic">
          Congenital Aneurysm of Left Ventricular Base.
        </span>{" "}
        CHEST 57:411-415, May, 1979.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Reynolds, J., Sugg, W.L.:{" "}
        <span className="italic">
          Bile Piyalism Associated with Chest Trauma and Sickle Cell Crisis
          Simulating Bronchobiliary Fistula.
        </span>{" "}
        Ann. Thorac. Surg. 13:537-542, June, 1972.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Gholston, Don E., Guide, Robert E., Martinez, H.E.:{" "}
        <span className="italic">
          Traumatic Left Anterior Descending Coronary Artery Fistula to the
          Right Ventricle.
        </span>{" "}
        J. Thorac. & Cardiovasc. Surg. Vol. 70#4:692-695, October, 1975.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Allison, Walter M., Guynes, W.A., Martinez, Henry E.:{" "}
        <span className="italic">
          Complete Obstruction of the Left Main Coronary Artery Associated with
          Congenital Pulmonary Valvular Stenosis.
        </span>{" "}
        CHEST 69:238-239, February, 1976.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Anthony, W.A., Martinez, H.E., Guynes, W.A.:{" "}
        <span className="italic">
          Bilateral Renal Vein and Inferior Vena Cava Thrombosis with Nephrotic
          Syndrome Treated by Thrombectomy: Case Report with Three Year Follow
          up.
        </span>{" "}
        J. Ural. 116:510, 511, March, 1976.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Martinez, Henry E., Guynes, W.A., Miller, LaWayne:{" "}
        <span className="italic">
          Post-operative Chest Wound Infections in Coronary Bypass Patients: A
          Controlled Study Evaluating Prophylactic Antibiotics.
        </span>{" "}
        J. Thorac. & Cardiovasc. Surg. 73:944-947, June, 1977.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D.:{" "}
        <span className="italic">
          Ascending Aortic Aneurysm Resection Eight Months After Triple Coronary
          Artery Bypass: Case Report
        </span>{" "}
        CARDIOVASCULAR DISEASES: Bulletin of Texas Heart Institute, Vol. 6, #1,
        pp. 59-60, March, 1979.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Fortner, B.R.:{" "}
        <span className="italic">
          Traumatic Thrombosis of Congenital Left Coronary Artery – Main
          Pulmonary Artery Fistulas.
        </span>{" "}
        CHEST 76:105-106, July, 1979.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Miller, LaWayne, Martinez, H.E., Guynes, W.A., Fife,
        Trey:{" "}
        <span className="italic">
          Coronary Arterial Bypass Operations without Antibiotic Coverage.
        </span>{" "}
        CHEST Volume 76, pp. 174-175, August 1979.
      </li>
      <li className={styles.listItem}>
        Guynes, W.A., Sutherland, R.D., Martinez, H.E.:{" "}
        <span className="italic">
          Tracheo-Esophagael Fistula Following Blunt Chest Trauma.
        </span>{" "}
        Texas Medicine. Volume 75, pp. 52-53, December, 1979.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Martinez, H.E., Guynes, W.A.:{" "}
        <span className="italic">
          Surgical Treatment of Left Main Coronary Artery Obstruction.
        </span>{" "}
        Texas Medicine. Volume 75, pp. 62-63, December, 1979.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Martinez, H.E., Guynes, W.A.:{" "}
        <span className="italic">
          A Rapid Secure Sternal Closure for Use in Patients with Poor Sternal
          Bone or Chronic Lung Disease.
        </span>{" "}
        CARDIOVASCULAR DISEASES: Bulletin of Texas Heart Institute, Vol. 8, #1,
        pp. 54-55, March, 1981.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Guynes, W.A., Michols, C.T., Martinez, H.E.:{" "}
        <span className="italic">
          Excessive Strut Wear Allowing Ball-Poppet-Embolication in
          Debarkey-Surgitool Aortic Valve Prosthesis.
        </span>{" "}
        J. Cardiovascular Surg. Volume 23, No. 2, pp. 179-180, March-April,
        1982.
      </li>
      <li className={styles.listItem}>
        Sutherland, R.D., Martinez, H.E., Guynes, W.A.:{" "}
        <span className="italic">
          Antibiotics and Coronary Artery Bypass Operations: An 11-Year
          Experience.
        </span>{" "}
        Infections in Surg., pp. 585-587, August, 1985.
      </li>
    </ol>
  );
};

export default BlockPublications;

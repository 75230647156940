import React, { Component } from "react";
import PropTypes from "prop-types";
import { Facebook } from "react-feather";
import NavMobile from "./nav-mobile";
import NavDesktop from "./nav-desktop";
import styles from "./header.module.scss";

class Header extends Component {
  render() {
    const { active } = this.props;

    return (
      <header className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.leader}>
            <a href={"/"}>R.D. Sutherland M.D.</a>
          </div>
          <div className={styles.social}>
            <a
              className={styles.icon}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/rd.sutherland.90"
            >
              <Facebook size={24} color="#1877f2" />
            </a>
          </div>
        </div>
        <div className={styles.navWrapper}>
          <div className={styles.navContainer}>
            <NavDesktop active={active} />
            <NavMobile active={active} />
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  active: PropTypes.string
};

export default Header;

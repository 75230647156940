import React, { Component, Fragment } from "react";
import cx from "classnames";
import styles from "./gallery.module.scss";
import BookOne from "../../../resources/images/book.jpg";
import BookTwo from "../../../resources/images/book2.jpg";
import { ReactComponent as Circle } from "../../../resources/icons/circle.svg";

const BookOneSlide = () => {
  return (
    <div className={cx([styles.fadeIn, styles.bookOneContainer])}>
      <div className="small-5 small-offset-1 my-auto">
        <h2 className={styles.title}>The Other Side of the Knife</h2>
        <p className={styles.content}>
          Will Dan Parker survive? From accomplished cardiovascular surgeon R.D.
          Sutherland, take a ride with Dr. Parker on an international chase for
          the holy grail of heart surgery, a self-contained implantable
          artificial heart.
        </p>
        <a href="/books" className={styles.btnRed}>
          Read More
        </a>
      </div>
      <div className="small-6 my-auto">
        <img
          alt="Book1"
          src={BookOne}
          className={styles.image}
        />
      </div>
    </div>
  );
};

const BookTwoSlide = () => {
  return (
    <div className={cx([styles.fadeIn, styles.bookTwoContainer])}>
      <div className="small-5 small-offset-1 my-auto">
        <h2 className={styles.title}>The Surgeon and the Arrow</h2>
        <p className={styles.content}>
          Jamie Swift, a pediatric heart surgeon and champion archer, performs a
          perfect operation on a one year old baby with a congenital heart
          defect, but the infant dies in the recovery room. Who is to blame?
        </p>
        <a href="/books" className={styles.btnBlueLight}>
          Read More
        </a>
      </div>
      <div className="small-6 my-auto">
        <img
          alt="Book2"
          src={BookTwo}
          className={styles.image}
        />
      </div>
    </div>
  );
};

class GalleryBooks extends Component {
  constructor(props) {
    super(props);
    this.state = { slide: 0 };
    this.slides = [<BookOneSlide />, <BookTwoSlide />];
    this.handleSlide = this.handleSlide.bind(this);
  }

  handleSlide = num => {
    this.setState({
      slide: num
    });
  };

  render() {
    const { slide } = this.state;
    const rendered = {
      slide: this.slides[slide] || this.slides[0],
      styles: slide === 0 ? styles.bookOneContainer : styles.bookTwoContainer,
      buttons: []
    };

    for (let i = 0; i < this.slides.length; i++) {
      rendered.buttons.push(
        <button
          key={i.toString()}
          className="mx-1/2"
          style={{ outline: "none" }}
          onClick={() => this.handleSlide(i)}
        >
          <Circle
            width={16}
            fill={i === slide ? "white" : "gray"}
            stroke={i === slide ? "white" : "gray"}
          />
        </button>
      );
    }

    return (
      <Fragment>
        <div className={styles.carousel}>
          <div className={styles.container}>
            {rendered.slide}
            <div className="absolute bottom-0 right-0 mr-4">
              <div className="flex flex-row flex-no-wrap justify-end content-end">
                {rendered.buttons}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GalleryBooks;

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./nav.module.scss";

const NavDesktop = props => {
  return (
    <div className={styles.navDesktop}>
      <nav className={styles.navbar}>
        <div>
          <a
            className={cx(
              styles.navlink,
              props.active === "books" && styles.active
            )}
            href="/books"
          >
            Books
          </a>
        </div>
        <div>
          <a
            className={cx(
              styles.navlink,
              props.active === "publications" && styles.active
            )}
            href="/publications"
          >
            Publications
          </a>
        </div>
        <div>
          <a
            className={cx(
              styles.navlink,
              props.active === "memberships" && styles.active
            )}
            href="/memberships"
          >
            Memberships
          </a>
        </div>
        <div>
          <a
            className={cx(
              styles.navlink,
              props.active === "photos" && styles.active
            )}
            href="/photos"
          >
            Photos
          </a>
        </div>
        <div>
          <a
            className={cx(
              styles.navlink,
              props.active === "contact" && styles.active
            )}
            href="/contact"
          >
            Contact
          </a>
        </div>
      </nav>
    </div>
  );
};

NavDesktop.propTypes = {
  active: PropTypes.string
};

export default NavDesktop;

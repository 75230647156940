import React, { Component } from "react";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import SectionRow from "../layouts/section";
import SectionTwoLeft from "../layouts/section-2col-left";
import SectionTwoRight from "../layouts/section-2col-right";
import { BlockAuthor, BlockPublications } from "./blocks";
import styles from "./main.module.scss";

class Publications extends Component {
  render() {
    return (
      <main className={styles.wrapper}>
        <Header active="publications" />
        <SectionRow>
          <SectionTwoLeft>
            <BlockAuthor />
          </SectionTwoLeft>
          <SectionTwoRight leader="Publications">
            <BlockPublications />
          </SectionTwoRight>
        </SectionRow>
        <Footer />
      </main>
    );
  }
}

export default Publications;
